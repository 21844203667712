<svelte:options accessors/>
<script>
    import NauiFilters from "../naui/components/NauiFilters.svelte";

    export let filters = [];
    export let data = [];
    export let hideDetails = false;
    export let nav = null;

    function hasWarning(ind) {
        return (ind.metric.value < ind.metric.target) === ind.metric.trend;
    }
</script>
<div>
    <NauiFilters {filters} on:filtered/>
    <div class="py-15">
        <table class="naui-table w-100">
            <thead>
            <tr>
                {#if nav}
                    <th></th>
                {/if}
                <th style="width: 50%">
                    <div>Indicadores</div>
                </th>
                <th style="width:35%" class="text-c">
                    <div class="box">Valor actual</div>
                </th>
                {#if !hideDetails}
                    <th class="text-c">
                        <div class="box">Detalles</div>
                    </th>
                {/if}
                {#if nav}
                    <th></th>
                {/if}
            </tr>
            </thead>
            <tbody>
            {#each data as ind, i (ind.slug)}
                <tr>
                    {#if nav}
                        <td class="box">
                            <a href="{nav.back}" class="accent">
                                <i class="fal fa-chevron-circle-left accent"></i>
                            </a>
                        </td>
                    {/if}
                    <td>
                        <div>
                            <div class="py-1">
                                <h4 class="mb-ii">{i + 1}. {ind.nombre}
                                    {#if hasWarning(ind)}
                                        <i class="ml-3 fas fa-exclamation-triangle with-warning"></i>
                                    {/if}
                                </h4>
                                <p>{ind.descripcion}</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="box">
                            <div class="grid-2 py-ii items-center mx-auto g-indicadores">
                                <div class="text-r first pr-1">
                                    <small class="indicador-label">{ind.metric.label}</small>
                                    <div class="indicador" class:with-warning={hasWarning(ind)}>{ind.metric.value}</div>
                                </div>
                                <div class="text-l second pl-1">
                                    <small class="indicador-label">Meta</small>
                                    <div class="indicador">{ind.metric.target}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    {#if !hideDetails}
                        <td>
                            <div class="box">
                                <a href="{ind.route}" class="btn-icon">
                                    <i class="fal fa-plus-circle accent"></i>
                                </a>
                            </div>
                        </td>
                    {/if}
                    {#if nav}
                        <td class="box">
                            <a href="{nav.forward}" class="accent">
                                <i class="fal fa-chevron-circle-right accent"></i>
                            </a>
                        </td>
                    {/if}
                </tr>
            {:else}
                <tr>
                    <td colspan="3">
                        <div>
                            <h2>No se encontraron indicadores</h2>
                        </div>
                    </td>
                </tr>
            {/each}
            </tbody>
        </table>
    </div>
</div>
<style>
    .indicador-label {
        font-weight: 600;
        color: #4D4D4D;
        margin-bottom: .25rem;
        display: block;
        font-size: 12px;
    }

    .indicador {
        font-size: 20px;
        font-weight: bold;
    }

    .g-indicadores .first {
        border-right: 1px solid var(--border-color);
    }

    .with-warning {
        color: var(--state-code-3);
    }
</style>
