<script>
    export let caption = null;
    export let icon = null;
    export let iconFamily = 'fal';
    export let mode = '';
    export let className='';
    export let size = '';
    export let color = '';
    export let type = 'button';
    export let href = null;
</script>
{#if caption}
    {#if href}
        <a class="btn {mode} {color} {size} {className}" href="{href}">
            {#if icon}
                <i class="{iconFamily} fa-{icon}"></i>
            {/if}
            <span>{caption}</span>
        </a>
    {:else}
        <button on:click class="btn {mode} {color} {size} {className}" type={type}>
            {#if icon}
                <i class="{iconFamily} fa-{icon}"></i>
            {/if}
            <span>{caption}</span>
        </button>
    {/if}
{:else if icon}
    {#if href}
        <a class="btn-icon" href="{href}">
            <i class="{iconFamily} fa-{icon} {color}"></i>
        </a>
    {:else}
        <button on:click class="btn-icon" type="{type}">
            <i class="{iconFamily} fa-{icon} {color}"></i>
        </button>
    {/if}
{/if}
<style>
    :host {
        display: block;
    }
</style>
